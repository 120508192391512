import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"


const button = "mt-4 text-xl hover:text-blue-400"

const InformationPage = () => (
  <Layout>
    <SEO title="Plan your visit" />
    <h1>Plan your visit</h1>

    <div className="mt-10">
        <button
          className={button}
          onClick={(e) => {
            document.getElementById("map").scrollIntoView({ behavior: 'smooth', block: 'start' })}
          }
        >
          Map
        </button>
    </div>
            <div>
                <button
                  className={button}
                  onClick={(e) => {
                    document.getElementById("visit").scrollIntoView({ behavior: 'smooth', block: 'start' })}
                  }
                >
                  Virutal Visit
                </button>
            </div>
            <div>
                <button
                  className={button}
                  onClick={(e) => {
                    document.getElementById("bring").scrollIntoView({ behavior: 'smooth', block: 'start' })}
                  }
                >
                  What to bring
                </button>
            </div>
            <div className="">
                <button
                  className={button}
                  onClick={(e) => {
                    document.getElementById("expect").scrollIntoView({ behavior: 'smooth', block: 'start' })}
                  }
                >
                  What to expect
                </button>
            </div>
            <div className="mb-10">
        <button
          className={button}
          onClick={(e) => {
            document.getElementById("info").scrollIntoView({ behavior: 'smooth', block: 'start' })}
          }
        >
          General Information
        </button>
    </div>
    <h2 className="mt-6" id="map">Map</h2>
      <div>
        <div className="flex flex-col lg:flex-row mt-6">
          <StaticImage
            src="../images/map.jpg"
            alt="Map to ronditch and sons"
            className=" lg:w-1/2 rounded-md"
          />
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.185675318565!2d-76.20253678449576!3d43.8519128079115016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d83ded4e534757%3A0x54dc99044f256e3f!2sCornell%20Rd%2C%20Henderson%2C%20NY%2013650!5e0!3m2!1sen!2sus!4v1648765110387!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="lg:w-1/2 lg:ml-4 mt-6 lg:mt-0"></iframe>
          </div>

          <div className="flex flex-col lg:flex-row mt-10">
            <div className=" mt-12 lg:mt-0 lg:w-1/2">
          <StaticImage
            src="../images/rudys_fishing_camp.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:mr-4 rounded-md"
          />
          <p>HERE !  Ruddy's Fishing Camp</p>
          </div>
          <div className="lg:w-1/2">
          <StaticImage
            src="../images/lighthouse.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:ml-4 mt-6 lg:mt-0 rounded-md"

          />
          <p className="lg:ml-4">SACKETS HARBOR LIGHTHOUSE</p>
          </div>
          </div>

          <div className="mt-10" id="bring">
            <h2>What to bring</h2>

            <ul>
              <li>Hat with visor</li>
              <li>sunglasses</li>
              <li>sunscreen</li>
              <li>Seasonal outerwear</li>
              <li>rain gear</li>
              <li>windbreaker</li>
              <li>A cooler to store your catch</li>
              <li>A camera to record your outing</li>
              <li>Appropriate fishing license</li>
              <li>All snacks and beverages in break proof containers</li>
            </ul>

          </div>

          <div className="mt-10" id="expect">
            <h2>Charters for the non-fisherman:</h2>
            <p>If you are looking for a leisurely day on the water, you've
come to the right place.  We offer one and two hour cruises
around the bays and islands of Henderson Harbor and a trip
to the village of historic Sackets Harbor where you can stroll
through the streets and visit the many gift and antiques
shops.  There is a wide variety of fine restaurants including:
<ul className="ml-4 my-4">
  <li>Sackets Harbor Brewing Company,</li>
  <li>Tin  Pan Galley,</li>
  <li>The
Boathouse restaurant,</li>
  <li>Good Fellos,</li>
  <li>Brick Oven Pizza,</li>
  <li>Sackets
Smokehouse,</li>
<li>The Novel Cafe,</li>
<li>and Chrissy Beanz Bakery and
Coffee Shop</li>
  </ul>
  Also visit the Barracks Inn in historic Madison
Barracks.  Begin your tour with the Sackets Harbor Visitor
Center and the Seaway Trail Discovery Center.
  </p>
          </div>

          <h2 className="mt-20" id="visit">Virtual visit</h2>
          <div className="flex flex-col lg:flex-row flex-wrap mt-10">
            <div className="lg:w-1/2">
          <StaticImage
            src="../images/1.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:mr-4 rounded-md"
          />
          <p>When you see this you will know you have arrived at < br/> <h3>Ron Ditch's Ruddy's Fishing Camp</h3></p>
          </div>
          <div className="lg:w-1/2">
          <StaticImage
            src="../images/2.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:ml-4 rounded-md"

          />
          <p className="lg:ml-4">We have a cottage, two full house keeping
apartments and a motel unit on the water</p>
          </div>
          </div>
          <div className="flex flex-row flex-wrap mt-10">
            <div className="lg:w-1/2">
          <StaticImage
            src="../images/three.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:mr-4 rounded-md"
          />
          <p>Enjoy the sunset from our deck
before or after dinner.  You can
either cook in or go to one of our
many excellent local restaurants</p>
          </div>
          <div className="lg:w-1/2">
          <StaticImage
            src="../images/4.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:ml-4 rounded-md"

          />
          <p className="lg:ml-4">Your kitchen</p>
          </div>
          </div>
          <div className="flex flex-row flex-wrap mt-10">
            <div className="lg:w-1/2">
          <StaticImage
            src="../images/5.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:mr-4 rounded-md"
          />
          <p>Your living room</p>
          </div>
          <div className="lg:w-1/2">
          <StaticImage
            src="../images/6.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:ml-4 rounded-md"

          />
          <p className="lg:ml-4">One of your two bedrooms</p>
          </div>
          </div>
          <div className="flex flex-row flex-wrap mt-10">
            <div className="lg:w-1/2">
          <StaticImage
            src="../images/ron.jpeg"
            alt="Cabin on the lake"
            className="h-128 lg:mr-4 rounded-md"
          />
          </div>
          <div className="lg:w-1/2">
          <StaticImage
            src="../images/homepage_boat.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:ml-4 mt-4 lg:mt-0 rounded-md"

          />
          </div>
          <p className="lg:ml-4">After a good night's rest, speed off to a fun filled day of fishing around the beautiful scenic islands of
Eastern Lake Ontario</p>
          </div>
          <div className="flex flex-row flex-wrap mt-10">
            <div className="lg:w-1/2">
          <StaticImage
            src="../images/8.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:mr-4 rounded-md"
          />
          <p>This nice small mouth bass is what
you are probably fishing for!</p>
          </div>
          <div className="lg:w-1/2">
          <StaticImage
            src="../images/9.jpg"
            alt="Cabin on the lake"
            className="h-128 lg:ml-4 rounded-md"

          />
          <p className="lg:ml-4">After we dock, if you wish, we will
professionally fillet and package
your catch</p>
          </div>
          </div>
<h2 className="mt-10" id="info"> General Information about the area</h2>
    </div>
    <div className="flex flex-col ml-6">
      <a className="mt-8" href="http://www.watertown.ny.us/">INFORMATION ON WATERTOWN,NY2</a>
      <a className="mt-6" href="http://sacketsharborny.com/"> NFORMATION ON SACKETS HARBOR,
  NY
  </a>
      <a className="mt-6" href="http://trailjeffersoncounty.com/">INFROMATION ON TRAILS AND RECREATOIN IN
  AREA</a>
      <a className="mt-6" href="http://co.jefferson.ny.us/">INFORMATION ON JEFFERSON
  COUNTY</a>
</div>
  </Layout>
)

export default InformationPage
